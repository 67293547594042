import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { fontSize } from "styled-system"

import Container from "../components/Container"
import Heading from "../components/Heading"
import Box from "../components/Box"
import Grid from "../components/Grid"
import RatioContainer from "../components/RatioContainer"
import PageHeader from "../components/PageHeader"
import Wave from "../components/Wave"
import SeoHelmet from "../components/Seo"

const StyledLink = styled(Link)`
  text-decoration: none;
  ${fontSize};
  color: inherit;
  &:hover,
  &:focus {
    color: inherit;
  }
`

const CustomRatioContainer = styled(RatioContainer)`
  img {
    transition: transform 270ms ease;
    &:hover {
    transform: scale(1.07);
    }
  }
`

const CaseStudiesPage = () => {
  const caseStudyData = useStaticQuery(graphql`
    query CaseStudyQuery {
      allDatoCmsProject(sort: { fields: position, order: ASC }) {
        nodes {
          id
          title
          client
          slug
          intro
          mainImage {
            alt
            url
          }
          cover {
            alt
            url
          }
          services {
            title
          }
          location {
            latitude
            longitude
          }
          projectColor {
            hex
          }
        }
      }
    }
  `)

  const projects = caseStudyData.allDatoCmsProject.nodes

  return (
    <>
      <SeoHelmet
        title="Case studies"
        description="We build unique digital platforms based on leading data science and web technologies."
        url={`http://lapidus.se/case-studies`}
      />
      <PageHeader
        title="Case studies"
        subtitle="We build unique digital platforms based on leading data science and web technologies."
      />

      <Container mt="5rem">
        <Grid>
          {projects.map(
            ({ id, slug, title, client, services, cover, projectColor }) => (
              <Box
                key={id}
                gridColumn={["span 12", "span 6"]}
                mb={["2.5rem", "5rem"]}
              >
                <StyledLink to={`/case-studies/${slug}`}>
                  <CustomRatioContainer
                    ratio={2 / 3}
                    bg={
                      projectColor
                        ? `${projectColor && projectColor.hex}`
                        : "#fff"
                    }
                    style={{ overflow: "hidden", borderRadius: "0.75rem" }}
                  >
                    <img
                      src={
                        cover && `${cover.url}?w600&h=400&dpr=2&fit=crop&auto=format`
                      }
                      alt={cover && cover.alt}
                      width={600}
                      height={400}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </CustomRatioContainer>
                  <Heading as="h3" fontSize={["md", "lg"]} my="0.75rem">
                    {title}
                  </Heading>
                  <Heading
                    as="h3"
                    fontSize={["md", "lg"]}
                    mt="0.75rem"
                    mb="2rem"
                    color="darkGrey"
                    fontWeight="500"
                  >
                    {client}
                  </Heading>
                </StyledLink>
              </Box>
            )
          )}
        </Grid>
      </Container>
      <Wave color="white" variant="bottom3" bg="navy" />
    </>
  )
}

export default CaseStudiesPage
